import { Component, OnInit } from '@angular/core';
import { DomainService } from '@app/shared/services';
import { registerLocaleData } from '@angular/common';
import locale from '@angular/common/locales/tr';

@Component({
  selector: 'app-header-v2',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderV2Component {
  constructor(private domainService: DomainService) {
    registerLocaleData(locale, 'tr');
  }
  transactionInfo = JSON.parse(localStorage.getItem('transaction'));
  language = JSON.parse(sessionStorage.getItem('language'));
  isLoading = false;
  domain: any | null = null;
  domainName: any | null = null;

  ngOnInit(): void {
    this.domain = this.domainService.get;
    this.domainName = this.domain.domain.split('.')[0];
  }
}
