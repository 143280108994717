import { Component, Inject, OnInit } from '@angular/core';
import { DomainService, RouteService, ThemeService } from '@shared/services';
import { LanguageService, AuthService } from 'ngx-venus';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  domain: any;
  domainName: string;
  headerType: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public authService: AuthService,
    private languageService: LanguageService,
    public routeService: RouteService,
    public domainService: DomainService,
    public themeService: ThemeService
  ) {
    this.domain = this.domainService.get;
    this.domainName = this.domain.domain.split('.')[0];
    this.headerType = this.domain.headerType || 'v1';
  }

  ngOnInit() {
    this.routeService.routeCheck();
    this.authService.authCheck();
    this.languageService.langCheck();
    this.document.body.classList.add(this.domainName);
    // domain based favicon
    this.themeService.addTag({
      rel: 'icon',
      href: '/assets/img/domain/favicon/' + this.domain.favicon,
    });
  }
}
