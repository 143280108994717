import { ErrorHandler, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AlertService } from 'ngx-venus';
@Injectable({
  providedIn: 'root',
})
export class ErrorService implements ErrorHandler {
  constructor(public alertService: AlertService, public http: HttpClient) {}
  handleError(error: Error | HttpErrorResponse) {
    if (!navigator.onLine) {
      return this.alertService.setNotice('No internet connection', 'warning');
    }
    // endpoints error formats may be different
    let message = '';
    if (error['error'] && typeof error['error'] === 'string') {
      message = error['error'];
    } else if (
      error['error'] &&
      error['error']['message'] &&
      typeof error['error']['message'] === 'string'
    ) {
      message = error['error']['message'];
    } else if (error['message'] && typeof error['message'] === 'string') {
      message = error.message;
    }

    if (message !== '401') {
      this.alertService.setNotice(message, 'warning');
    }

    this.http.post('/cli-e-hand', { error: message }).subscribe({
      next: (response) => console.log('Error logged on server:', response),
      error: (err) => console.error('Failed to send error to server:', err),
    });
  }
}
