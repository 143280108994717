import { tap } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'ngx-venus';
import { Router } from '@angular/router';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    @Inject('systemConfig') public SystemConfig,
    private router: Router,
    private authService: AuthService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (response) => {
          // http status 200, body.status 401
          if (response instanceof HttpResponse) {
            if (response.body && response.body.code === '401') {
              throw new Error(response.body.message || 'Token is expired.');
            }
          }
        },
        (error) => {
          // http status 401
          if (error.status === 401) {
            this.router.navigate([this.SystemConfig.unauthorizedRedirectTo]);
            throw new Error('401');
          }
        }
      )
    );
  }
}
