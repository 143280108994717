import { systemConfig } from './system.config';
export const routeConfig = {
  // Payment Methods
  'payment-methods': {
    title: 'Select Deposit Method',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  'credit-card': {
    title: 'Pay with Credit Card',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  card: {
    title: 'Pay with Card',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  'bank-transfer': {
    title: 'Pay with Bank Transfer',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  'oto-bank-transfer': {
    title: 'Pay with Oto Bank Transfer',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },

  'manual-bank-transfer': {
    title: 'Pay with Manual Bank Transfer',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  'direct-instant-bank-transfer': {
    title: 'Pay with Direct Instant Bank Transfer',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  cepbank: {
    title: 'Pay with Cepbank',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  'indian-atm': {
    title: 'Pay with Indian Atm',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  neteller: {
    title: 'Pay with Neteller',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  boleto: {
    title: 'Pay with Boleto',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  'pago-efectivo-bt': {
    title: 'Pay with Pago Efectivo Bt',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  'pago-efectivo-cp': {
    title: 'Pay with Pago Efectivo Cp',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  'pec-flash': {
    title: 'Pay with PEC Flash',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  flexepin: {
    title: 'Pay with Flexepin',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  qiwi: {
    title: 'Pay with Qiwi',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  'peppara-epin': {
    title: 'Pay with Peppara Epin',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  sparkpay: {
    title: 'Pay with Sparkpay',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  'redeem-voucher': {
    title: 'Pay with Prepaid Card',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  trustpay: {
    title: 'Pay with TrustPay',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  trustly: {
    title: 'Pay with Trustly',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  fintecsystems: {
    title: 'Pay with FinTecSystems',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  pix: {
    title: 'Pay with Pix',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  'instant-qr': {
    title: 'Pay with Instant QR',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  btvoucher: {
    title: 'Pay with Bank Transfer Voucher',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  upi: {
    title: 'Pay with UPI',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  konbini: {
    title: 'Pay with Konbini',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  payeasy: {
    title: 'Pay with Payeasy',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },

  // Pay By Link
  'pay-by-link': {
    title: 'Pay by Link',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  // Community Bank
  'community-bank-initialize': {
    title: 'Community Bank',
    permissions: {
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
  // Not found
  '404': { title: 'Page Not Found' },

  // Unauthorized
  '401': { title: 'Session Expired' },

  'iframe-notfound': { title: 'Iframe Not Found' },
  // Payment Result
  'payment-result': {
    title: 'Payment Result',
    permissions: {
      only: ['APP_ACCESS'],
      redirectTo: systemConfig.unauthorizedRedirectTo,
    },
  },
};
