<div class="pHeader">
    <div class="logo">
        <img [src]="'assets/img/domain/logo/' + domain.logo" [alt]="domain.name + ' logo'" />
    </div>

    <div class="name">
        <span>
            {{ transactionInfo?.customerFullName }}
        </span>
        <app-language-change></app-language-change>
    </div>

    <div class="amount-container">
        <div *ngIf="transactionInfo?.amount" class="amount">
            <span>
                {{ transactionInfo?.amount }}
            </span>
            <sup>{{ transactionInfo.currency }}</sup>
        </div>
        <div *ngIf="transactionInfo?.minAmount || transactionInfo?.maxAmount" class="amount">
            <span>
                {{ transactionInfo?.minAmount + '-' + transactionInfo?.maxAmount }}
            </span>
            <sup>TRY</sup>
        </div>
    </div>
</div>