import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/public/public.module').then((m) => m.PublicModule),
  },
  {
    path: 'payment-methods',
    loadChildren: () =>
      import('./modules/payment-methods/payment-methods.module').then(
        (m) => m.PaymentMethodsModule
      ),
  },
  {
    path: 'pay-by-link',
    loadChildren: () =>
      import('./modules/pay-by-link/pay-by-link.module').then(
        (m) => m.PayByLinkModule
      ),
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy,
    }),
  ],
  exports: [RouterModule],
})
export class RoutingModule {}
