import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute, Route } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  onRouteChange$ = new BehaviorSubject<any | null>(null);
  isInitialized$ = new BehaviorSubject<any | null>(null);

  constructor(
    @Inject('routeConfig') public routeConfig: any,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private browserTitle: Title,
    private translate: TranslateService
  ) {}

  routeCheck() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          const token = route.snapshot.queryParams['token'];
          // this means user initialized new transaction
          if (token) {
            this.isInitialized$.next(false);
          } else {
            this.isInitialized$.next(true);
          }

          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter((route) => route.outlet === 'primary'))
      .pipe(mergeMap((route) => route.data))
      .subscribe((route) => {
        if (route && route['routeConfig']) {
          const data = this.routeConfig[route['routeConfig']];
          this.browserTitle.setTitle(this.translate.instant(data.title));
          this.onRouteChange$.next(route);
        }
      });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
