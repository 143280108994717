<div class="pHeader">
    <div class="row">
        <div class="col-8">
            <div class="idTag"></div>
            <div *ngIf="
          transactionInfo?.amount && domainName === 'speedy';
          else elseBlock
        " class="amount">
                {{
          transactionInfo?.amount
            | currency: transactionInfo.currency:'symbol-narrow':'1.2-2':'tr'
        }}
            </div>
            <ng-template #elseBlock>
                <div *ngIf="transactionInfo?.amount" class="amount">
                    {{ transactionInfo?.amount }}<sup>{{ transactionInfo.currency }}</sup>
                </div>
            </ng-template>
            <div *ngIf="transactionInfo?.minAmount || transactionInfo?.maxAmount" class="amount">
                {{ transactionInfo?.minAmount + '-' + transactionInfo?.maxAmount
        }}<sup>TRY</sup>
            </div>
        </div>
        <div class="col-4 right">
            <app-language-change></app-language-change>
            <div class="name">
                {{ transactionInfo?.customerFullName }}
            </div>
        </div>
    </div>
</div>