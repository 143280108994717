<div *ngIf="domain.logoExists && routeService.isInitialized$ | async" class="logo">
    <img *ngIf="authService.isAuthorized$ | async" [src]="'assets/img/domain/logo/' + domain.logo" [alt]="domain.name + ' logo'" />
</div>
<div class="capsule">
    <div *ngIf="routeService.isInitialized$ | async">
        <ng-container *ngIf="authService.isAuthorized$ | async">
            <app-header *ngIf="headerType === 'v1'"></app-header>
            <app-header-v2 *ngIf="headerType === 'v2'"></app-header-v2>
        </ng-container>
    </div>
    <app-alert></app-alert>
    <router-outlet></router-outlet>
</div>