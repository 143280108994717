import { Injectable } from '@angular/core';
import domainList from '../../../../../domain';

@Injectable({
  providedIn: 'root',
})
export class DomainService {
  domain: string | null = null;

  constructor() {
    const hostName = window.location.hostname;
    const domainName = hostName.substring(
      hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1
    );

    if (domainList[domainName]) {
      this.domain = domainList[domainName];
    } else {
      this.domain = domainList[Object.keys(domainList)[0]];
    }
  }

  get get() {
    return this.domain;
  }
}
