import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'ngx-venus';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    public translate: TranslateService,
    private authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // can be use to exlude auth interceptor // no need to enter query params
    const excludedUrl = [
      'https://api.ipify.org',
      '/api/v2/checkout/getPaymentMethodDurationType',
      '/api/v2/getPayByLinkUrl',
    ];

    // exp: /api/v2/getPayByLinkUrl coming with id query params and id different in every request
    let url = '';
    if (request.url.includes('?')) {
      url = request.url.split('?')[0];
    } else {
      url = request.url;
    }

    if (!excludedUrl.includes(url)) {
      const init = this.authService.auth;
      if (init && init.token) {
        request = request.clone({
          setHeaders: {
            Authorization: `${init.token}`,
            'Accept-Language': `${this.translate.currentLang}`,
          },
        });
      }
    }
    return next.handle(request);
  }
}
