import { Component, OnInit } from '@angular/core';
import { DomainService } from '@app/shared/services';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-language-change',
  templateUrl: './language-change.component.html',
})
export class LanguageChangeComponent implements OnInit {
  languages: any = {
    en: 'EN',
    ja: 'JA',
    pt: 'PT',
    tr: 'TR',
    no: 'NO',
    pl: 'PL',
    es: 'ES',
    hu: 'HU',
  };

  language = '';
  domain: any = null;

  constructor(
    public translate: TranslateService,
    public domainService: DomainService
  ) {
    this.domain = this.domainService.get;
  }

  ngOnInit() {
    this.domain = this.domainService.get;
    const savedLanguage = localStorage.getItem('language');
    console.log('savedLanguage', savedLanguage);
    this.language = savedLanguage || this.translate.currentLang;
    if (this.domain.domain === 'speedy.io') {
      for (const lang in this.languages) {
        if (lang !== 'en' && lang !== 'pt') {
          delete this.languages[lang];
        }
      }
    }

    this.translate.addLangs(Object.keys(this.languages));
  }

  langChange(lang: any) {
    this.translate.use(lang);
    sessionStorage.setItem('language', JSON.stringify(lang));
  }
}
