import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';

// modules
import { SharedModule } from '@shared/shared.module';

// interceptor
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { UnauthorizedInterceptor } from '@core/interceptors/unauthorized.interceptor';

// services
import { ErrorService } from '@core/services/error.service';

// configs
import { routeConfig, systemConfig } from '@core/configs';

@NgModule({
  imports: [RouterModule, FormsModule, HttpClientModule, SharedModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: ErrorService },
    { provide: 'routeConfig', useValue: routeConfig },
    { provide: 'systemConfig', useValue: systemConfig },
  ],
})
export class CoreModule {}
